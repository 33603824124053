import { gql } from '@apollo/client';

export { IDENTITY } from './identity';

export const HEALTH_LINKS = gql`
  query HealthLinks($id: ID) {
    viewerTwo(id: $id) {
      id
      existingApplicationInfo {
        hasActivePolicy
        hasPreviousActivePolicy
        hasSubmittedApplication
        existingApplications {
          id
          familyName
          givenName
          coverageYearNumber
          state
          applicationIdentifier
          applicationVersion
          providerLastUpdatedDate
          applicationStatus
          hasExistingPersonTrackingNumbersTiedToApplication
          matchesWithAnotherUser
        }
      }
    }
  }
`;

export const EXPLORER = gql`
  query Explorer($id: ID) {
    viewerTwo(id: $id) {
      id
      health {
        healthPreference {
          ownerID
          financialPreference
          plannedUsage
          drugsWanted {
            name
            rxcui
          }
          providersWanted {
            name
            npi
          }
        }
      }
      healthExplorerData {
        id
        zipcode
        countyfips
        coverageYear
        state
        income
        providerPlanID
        pathwayType
        email
        dependents {
          id
          age
          sex
          relation
          aptcEligible
          hasMec
          isApplying
          isParent
          isPregnant
          isSmoker
        }
        providerPlanID
        healthPlans {
          plans {
            healthPlan {
              planID: id
              name
              type
              metalLevel
              premium
              premiumWithCredit
              benefitsURL
              issuer {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const PX = gql`
  query PublicHealthSession($healthID: ID!) {
    getPublicHealthExplorerData(id: $healthID) {
      id
      zipcode
      countyfips
      coverageYear
      state
      income
      providerPlanID
      pathwayType
      email
      financialPreference
      plannedUsage
      drugsWanted {
        rxcui
        name
      }
      providersWanted {
        npi
        name
      }
      dependents {
        age
        sex
        relation
        aptcEligible
        hasMec
        isApplying
        isParent
        isPregnant
        isSmoker
      }
      providerPlanID
      healthPlans {
        plans {
          healthPlan {
            planID: id
            name
            type
            metalLevel
            premium
            premiumWithCredit
            benefitsURL
            issuer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const BG_ENROLLMENTS = gql`
  query UserEnrollmentsPreload($id: ID) {
    viewerTwo(id: $id) {
      id
      health {
        applications(input: { includeDeleted: true }) {
          id
          applicationContext
          applicationOrigin
          applicationVersionNum
          asyncStatus
          coverageState
          coverageYearNumber
          createdOn
          eligibilityLastFetched
          hasSubmittedPermissions
          householdIncome
          insuranceApplicationIdentifier
          lastUpdated
          lastUsedRoute
          lastUserUpdate
          pathwayType
          planPremium
          planPremiumEffective
          status
          isDeleted
          aptcHouseholdAmount
          isEligibilityDetermined
        }
        policies(input: { includeDeleted: true }) {
          id
          carrier
          planName
          premium
          premiumEffective
          aptcAmount
          policyStatus
          startDate
          endDate
          isPendingDocUpload
          exchangePolicyNumber
          paymentStatus
          agentID
          isDeleted
        }
      }
    }
  }
`;

export const USER = gql`
  query UserBasic($id: ID) {
    viewer(id: $id) {
      id
      user {
        id
        givenName
        familyName
        nickname
        avatarImage
        email
        intercomID
      }
    }
  }
`;

export const NAME = gql`
  query UserViewBaseQuery5($id: ID) {
    viewer(id: $id) {
      id
      user {
        id
        givenName
        familyName
        nickname
        avatarImage
        email
        intercomID
      }
      savingsAccountMetadata {
        savingsProviderType
        isAccountLocked
      }
      wealthAccount {
        id
        isDisabled
      }
      savingsAccount {
        kyc
        kycIdvRequired
        notes
        id: providerUserId
      }
    }
  }
`;

export const UPLOADS = gql`
  query Uploads($id: ID) {
    admin: viewer {
      id
    }
    viewerTwo(id: $id) {
      id
      documentUploads {
        id
        createdOn
        ext
        documentType
        url
      }
    }
  }
`;

export const ADMINS = gql`
  query Admins {
    viewer {
      id
      user {
        id
        email
        givenName
        nickname
        familyName
        avatarImage
      }
    }
    admins {
      id
      givenName
      familyName
      email
      nickname
      avatarImage
    }
  }
`;

export const FETCH_DISABLE_USER_STATUS = gql`
  query DisableUserStatus($personId: ID!) {
    disableUserStatus(personID: $personId) {
      status
      savingsDisabled
      aggregationDisabled
      wealthDisabled
      goalsDisabled
      hasBalance
    }
  }
`;

export const HEALTH_NEW = gql`
  query HealthPageNew($id: ID, $input: ApplicationsInput) {
    viewerTwo(id: $id) {
      id
      health {
        applications(input: $input) {
          id
          applicationContext
          applicationOrigin
          applicationVersionNum
          asyncStatus
          coverageState
          coverageYearNumber
          createdOn
          eligibilityLastFetched
          hasSubmittedPermissions
          householdIncome
          insuranceApplicationIdentifier
          lastUpdated
          lastUsedRoute
          lastUserUpdate
          pathwayType
          planPremium
          planPremiumEffective
          status
          isDeleted
          aptcHouseholdAmount
          isEligibilityDetermined
          dmis {
            id
            status
            statusCodes
            subType
            verificationType
            resolutionStatus
            resolveBy
            lastReviewed
          }
          svis {
            id
            status
            statusCode
            resolveBy
            lastReviewed
          }
        }
      }
    }
  }
`;

export const HEALTH_NEW_POLS = gql`
  query HealthPageNewPols($id: ID) {
    viewerTwo(id: $id) {
      id
      health {
        policies(input: {}) {
          id
          carrier
          planName
          premium
          premiumEffective
          aptcAmount
          policyStatus
          startDate
          endDate
          isPendingDocUpload
          exchangePolicyNumber
          paymentStatus
          agentID
          isDeleted
          providerPlan {
            id
            name
            market
            metalLevel
            type
            premium
            premiumWithCredit
            productDivision
            issuer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const HEALTH_INSPECTOR_NEW = gql`
  query HealthInspectorNew($id: ID, $appId: ID!) {
    viewerTwo(id: $id) {
      id
      health {
        application(id: $appId) {
          id
          isEligibilityDetermined
          applicationContext

          # screeningPhase
          enrollment {
            id
            # isOpenEnrollment
          }
          policies {
            id
            agentID
          }
          screening {
            isRequestingFinancialAssistance
            areAllSameAddress
            isPlanningToFileTax
            isResponsibleForChildNotOnTaxReturn
            isIndianOrAlaskan
            isCoveredThroughJobOrCobra
            isClaimingAllDependentsOnTax
            isDependentSingleChild25OrYounger
            areDependentsLivingWithParentNotOnTaxReturn
            isOfferedIchra
            areDependentsChildOrStepchildSingleUnder26
          }
          maritalStatus
          # numTaxDependents
          isSelfCoverage
          isSpouseCoverage
          # numDependentsRequestCoverage
          householdIncome
          isRequestingFinancialAssistance
          areAllSameAddress
          isPlanningToFileTax
          isResponsibleForChildNotOnTaxReturn
          isIndianOrAlaskan
          isCoveredThroughJobOrCobra
          isClaimingAllDependentsOnTax
          isDependentSingleChild25OrYounger
          areDependentsLivingWithParentNotOnTaxReturn
          isOfferedIchra
          areDependentsChildOrStepchildSingleUnder26
          isMedicaidRequirementAgreement
          isAbsentParentAgreement
          isChangeInformationAgreement
          isTerminateCoverageOtherMecFoundAgreement
          isRenewalAgreement
          isReuseTaxDataPermission
          renewEligibilityYearQuantity
          isPenaltyOfPerjuryAgreement
          isNonIncarcerationAgreement
          signatureText
          signatureDate
          signatureType
          insuranceApplicationIdentifier
          processStatus
          coverageYearNumber
          coverageState
          isResidesInApplicationState
          aptcAgreement
          aptcSignature
          aptcHouseholdAmount
          aptcHouseholdToApply
          aptcHouseholdRemaining
          selectedPlanID
          selectedPlanIssuerID
          selectedMetalLevel
          planPremium
          planPremiumEffective
          status
          applicationVersionNum
          isEligibilityDetermined
          incomeExplanationRequiredIndicator
          incomeExplanationRequiredReason
          incomeExplanationDocumentedAmount
          lastUsedRoute
          appliedCSRLevel
          isPrefill
          lastUpdated
          isStartedApplication
          pathwayType
          applicationOrigin
          lastUserUpdate
          createdOn
          hasAnyMedicaidChipEligibleMembers
          hasAllMedicaidChipEligibleMembers
          isTaxCreditReconciled
          hasSubmittedPermissions
          eligibilityLastFetched
          uiQuestionsToDisplay
          isEligibilityDetermined
          # hasUnemploymentIncome
          asyncStatus
          eligibility {
            totalAptc
            csrLevel
            isMedicaid
            isChip
          }
          dmis {
            id
            status
            statusCodes
            subType
            verificationType
            resolutionStatus
            resolveBy
            lastReviewed
          }
          svis {
            id
            status
            statusCode
            resolveBy
            lastReviewed
          }
          ednDocuments {
            id
            dsrsIdentifier
            documentCreationDate
          }
          applicant {
            id
            givenName
            middleName
            familyName
            ssn
            fullSSN
            dob
            phone {
              number
            }
            homeAddress {
              street1
              street2
              city
              state
              zip
            }
          }
          members {
            id
            givenName
            familyName
            middleName
            relation
            sex
            dob
            email
            isSmoker
            ssn
            annualTaxIncome {
              incomeAmount
            }
            metalLevelOption {
              allowedMetalLevels
              currentMetalLevel
              currentPlanOnlyIndicator
            }
            aptcEligibility {
              startDate
              endDate
              amount
              change
            }
            csrEligibility {
              startDate
              endDate
              csrLevel
              change
            }
          }
          enrollmentGroups {
            id
            members {
              id
              givenName
              familyName
              relation
              isRequestingCoverage
              isTaxDependent
              hasAbsentParent
            }
            plan {
              id
              issuer {
                id
                name
              }
              deductibles {
                amount
                type
                familyCost
                networkTier
              }
              name
              metalLevel
              type
              premium
              premiumWithCredit
            }
          }
        }
      }
    }
  }
`;
